<!-- 拼团 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage";
import {
  getDrawList,
  deleteDraw,
  auditDraw,
  updateIsEnable,
  copyActivity,
} from "@/api/O2OMall/marketing/luckDraw"; //接口api
export default {
  name: "fightGroup",
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        labelWidth: "80",
        getListApi: getDrawList,
        body: {},
        listNo: true, // 序号
        title: "拼团",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ queryBegTime: a, queryEndTime: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动编号" },
              { filter: "billNames", label: "活动名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            icon: "el-icon-document",
            click: "audit",
            label: "审核",
            type: "success",
            alertText: "确认要审核选中的抽奖活动吗？",
            btnType: "button",
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            num: 1,
            btnType: "dropdown",
            alertText: "确认要启用选中的抽奖活动吗？",
            other: [
              {
                click: "redoEnable",
                alertText: "确认要禁用选中的抽奖活动吗？",
                label: "禁用",
              },
            ],
          },
          {
            icon: "el-icon-document-copy",
            click: "copy",
            label: "复制",
            alertText: "确认要复制选中的抽奖活动吗？",
            type: "success",
            btnType: "button",
          },
          {
            click: "del",
            label: "删除",
            alertText: "确认要删除选中的抽奖活动吗？",
            type: "danger",
            icon: "el-icon-delete",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "活动编号",
            type: "link",
            click: "toDetail",
            minWidth: 200,
          },
          {
            prop: "billName",
            label: "活动名称",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            minWidth: 160,
            formatter: (v) => (v == 0 ? "未审核" : "已审核"),
          },
          {
            prop: "isStop",
            label: "启用状态",
            minWidth: 160,
            formatter: (v) => (v ? "禁用" : "启用"),
          },
          {
            prop: "begTime",
            label: "开始时间",
            minWidth: 160,
          },
          {
            prop: "endTime",
            label: "结束时间",
            minWidth: 160,
          },
          {
            prop: "userEveryOneMaxPrizeTimesCount",
            label: "抽奖次数",
            minWidth: 160,
          },
          {
            prop: "userPrizeTimesCount",
            label: "中奖次数",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      let msg = "";
      switch (type) {
        case "toDetail":
          this.$router.push({
            path: "/O2OMall/marketing/luckDraw/luckDrawDetail",
            query: {
              billId: row.billId,
            },
          });
          break;
        case "add":
          this.$router.push({ name: "LuckDrawDetail" });
          break;
        case "audit":
          await auditDraw({
            auditStatus: 1,
            luckDrawIds: selectData.map((x) => x.billId),
          });
          msg = "审核";
          break;
        case "enable":
          if (selectData[0].billStatus === 2) {
            await updateIsEnable({
              isStop: false,
              luckDrawIds: selectData.map((x) => x.billId),
            });
            msg = "启用";
          } else {
            return this.$message.error("请先审核该活动！");
          }
          break;
        case "redoEnable":
          await updateIsEnable({
            isStop: true,
            luckDrawIds: selectData.map((x) => x.billId),
          });
          msg = "禁用";
          break;
        case "del":
          await deleteDraw(selectData.map((x) => x.billId));
          msg = "删除";
          break;
        case "copy":
          await copyActivity(selectData.map((x) => x.billId));
          msg = "复制";
          break;
        default:
          break;
      }
      if (["audit", "enable", "redoEnable", "del", "copy"].includes(type)) {
        this.$modal.msgSuccess(msg + "成功");
        this.$refs.tablePage.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
